import Image from 'react-bootstrap/Image';
import wesley_cast1 from '../static/imgs/wesley_cast1.jpg';

const Footer = (props) => {

    return (
        <div>
            <Image src={wesley_cast1} alt="Tommy and Wesley Locke Fly Casting" fluid/>
        </div>
    );

}

export default Footer;