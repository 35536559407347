import { Row, Col } from 'react-bootstrap';
import YouTube from 'react-youtube';
import gray_background from '../static/imgs/gray_background.jpg';

const Video = (props) => {
    const opts = {
        height: '400',
        width: '100%',
        playerVars: {
            autoplay: 0,
        }
    }
    
    return (
        <div className="mb-2 py-4" style={{backgroundImage: `url(${gray_background})`, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat"}}>
            <Row className="justify-content-center">
                <Col sm={12} md={8} lg={8}>
                    <YouTube videoId="MozNSwrjkco" opts={opts}/>
                </Col>
            </Row>
        </div>
    );
}

export default Video;