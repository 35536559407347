import { motion } from 'framer-motion';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Form, Row, Col } from 'react-bootstrap';

const Contact = (props) => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_zhdrybk', 'template_a5tgxts', form.current, 'UxS32pu1AL_WHTQPW')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        props.showHide();
        props.sent();
    };

    return (
        <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 0, duration: .5 }}>
            <Row>
                <Col sm={8} md={8} lg={6} className="text-center m-auto p-4 rounded">    
                    <Form ref={form} onSubmit={sendEmail} className="form">
                        <Form.Group className="d-flex align-items-center justify-content-between mb-3">
                            <Form.Label className="me-3 text-light" style={{fontWeight: "bold", textShadow: "1px 1px 2px black"}}>Name</Form.Label>
                            <Form.Control type="text" name="user_name" className="w-75"/>
                        </Form.Group>
                        <Form.Group className="d-flex align-items-center justify-content-between mb-3">
                            <Form.Label className="me-3 text-light" style={{fontWeight: "bold", textShadow: "1px 1px 2px black"}}>Email</Form.Label>
                            <Form.Control type="email" name="user_email" className="w-75"/>
                        </Form.Group>
                        <Form.Group className="d-flex align-items-center justify-content-between mb-3">
                            <Form.Label className="text-light me-3" style={{fontWeight: "bold", textShadow: "1px 1px 2px black"}}>Message</Form.Label>
                            <Form.Control as="textarea" rows={3} name="message" className="w-75"/>
                        </Form.Group>
                        <motion.button type="submit" className="btn btn-outline-light" style={{fontWeight: "bold", textShadow: "1px 1px 2px black"}} whileHover={{ scale: 1.1 }}>
                            Send
                        </motion.button>
                    </Form>        
                </Col>
            </Row>
        </motion.div>
    )
}

export default Contact;