import master_the_craft from '../static/imgs/master_the_craft.jpg';

const MasterTheCraft = (props) => {
    
    return (
        <div className="mt-2" style={{marginBottom: "0"}}>
            <img src={master_the_craft} alt="Tommy Locke Poling" style={{width: "100%"}}/>
        </div>
    );
}

export default MasterTheCraft;