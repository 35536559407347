import { Row, Col, Image } from 'react-bootstrap';

const About = (props) => {

    return (
        <div className="bg-dark mb-3 mt-2">
            <Row className="justify-content-center p-4 mb-5">
                <Col md={10} lg={10}>
                    <h2 className="nothingYouCouldDo text-light">Tommy Locke has spent the last 40 years fishing Florida’s backcountry. He has dedicated his life to understanding the mysteries in the shallows and more specifically, the elusive Tarpon. Tommy is a teaching guide, thrilled to be working with the expert angler and the newcomer alike. He aims to further the craft while protecting Florida’s greatest resource.</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3 className="robotoBoldItalic me-3 p-2 text-center text-light" style={{textShadow: "1px 1px 2px black"}}>Tommy's Episode on Millhouse Podcast</h3>
                </Col>
            </Row>
        </div>
    );
}

export default About;