import { motion } from 'framer-motion';
import { Row, Col } from 'react-bootstrap';

const Sent = (props) => {


    return (
        <>
            <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 0, duration: 1 }} className="bg-dark w-25 rounded mx-auto">
                <Row>
                    <Col sm={8} md={8} lg={6} className="text-center m-auto p-4 bg-dark rounded d-flex align-items-center justify-content-center">    
                        <h4 className="text-center text-light" style={{fontFamily: "Arial, sans-serif", fontWeight: "bold", textShadow: "1px 1px 2px black"}}>Email sent.</h4>       
                    </Col>
                </Row>
            </motion.div>
        </>
    )
}

export default Sent;