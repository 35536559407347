import { useAnimate, motion } from 'framer-motion';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Image } from 'react-bootstrap';

const Socials = (props) => {
    
    const [scope, animate] = useAnimate();
    
    useEffect(() => {
        animate(
            scope.current,
            { opacity: [0,1] },
            { duration: 2 },
        )
    }, []);

    return (
        <>
            <div className="bg-dark p-4 mb-2">
                <Row className="justify-content-center mb-4">
                    <h1 className="text-center text-light nothingYouCouldDo">Cast a line...</h1>
                </Row>
                <Row ref={scope}>
                        <Col lg={{span: 6, offset: 3}} className="text-center d-flex justify-content-evenly">
                            <Link to={{ pathname: "//instagram.com/tommylockeoutdoors" }} target="_blank" rel="noreferrer">
                                <motion.div whileHover={{ scale: 1.3 }}>
                                    <Image src={require("../static/imgs/instagram.png")} style={{height: "60px"}}/>
                                </motion.div>
                            </Link>
                            <Link to={{ pathname: "//www.facebook.com/tommylockeoutdoors/"}} target="_blank" rel="noreferrer">
                                <motion.div whileHover={{ scale: 1.3 }}>
                                    <Image src={require("../static/imgs/facebook.png")} style={{height: "60px"}}/>
                                </motion.div>
                            </Link>
                            <motion.div whileHover={{ scale: 1.3 }}>
                                <Image src={require("../static/imgs/email.png")} style={{height: "60px"}} onClick={ props.showHide }/>
                            </motion.div>
                        </Col>
                    </Row>
            </div>
        </>
    );
}

export default Socials;