import NavigationBar from "../components/NavigationBar";
import MasterTheCraft from "../components/MasterTheCraft";
import Video from "../components/Video";
import About from "../components/About";
import Socials from "../components/Socials";
import Contact from "../components/Contact";
import Sent from "../components/Sent";
import { useState } from 'react';
import Footer from '../components/Footer';

const Home = (props) => {
    const [contact, setContact] = useState(false);
    const [alert, setAlert] = useState(false);

    const showHide = () => {
        contact ? setContact(false): setContact(true);
        setAlert(false);
    }

    const sent = () => {
        alert ? setAlert(false): setAlert(true);
    }
    
    return (
        <div className="bg-dark">
            <NavigationBar/>
            <MasterTheCraft/>
            <About/>
            <Video/>
            <Socials contact={contact} showHide={showHide}/>
            {
                contact ? <Contact showHide={ showHide } sent={ sent }/> : null    
            }
            {
                alert ? <Sent/> : null
            }
            <Footer/>
        </div>
    );
}

export default Home;